import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { AFFECTED_TYPES_IDS, REPORT_TYPES_IDS } from 'src/utils/constants'
import {
	AuthContext,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import { DatePicker, DatePickerTypeEnum, UfinetInput, UfinetSectionBox, UfinetSelect } from 'ufinet-web-components'
import { useAffectionTypeFindAllQuery } from '../../../../modules/affectationTypes/application/AffectationTypeQueries'
import { AffectationType } from '../../../../modules/affectationTypes/domain/AffectationType'
import { HttpAffectationTypeRepository } from '../../../../modules/affectationTypes/infrastructure/HttpAffectationTypeRepository'
import { useDegradationTypeFindAllQuery } from '../../../../modules/degradationTypes/application/DegradationTypeQueries'
import { DegradationType } from '../../../../modules/degradationTypes/domain/DegradationType'
import { HttpDegradationTypeRepository } from '../../../../modules/degradationTypes/infrastructure/HttpDegradationTypeRepository'
import { useNetworkElementTypeFindAllQuery } from '../../../../modules/networkElementTypes/application/NetworkElementTypeQueries'
import { NetworkElementType } from '../../../../modules/networkElementTypes/domain/NetworkElementType'
import { HttpNetworkElementTypeRepository } from '../../../../modules/networkElementTypes/infrastructure/HttpNetworkElementTypeRepository'
import { useReportClassificationFindAllQuery } from '../../../../modules/reportClassification/application/ReportClassificationQueries'
import { ReportClassification } from '../../../../modules/reportClassification/domain/ReportClassification'
import { HttpReportClassificationRepository } from '../../../../modules/reportClassification/infrastructure/HttpReportClassificationRepository'

interface IncidentInfoSectionProps {
	formik: any
	reportTypes: IUfinetSelectOption[]
	loadingReportTypes: boolean
	setSelectedReportType: (type: IUfinetSelectOption) => void
	setSelectedAffectedType: (type: IUfinetSelectOption) => void
	servicesCustomer: IUfinetSelectOption[] | undefined
	getServicesCustomer: (clientId: string, global?: string) => void
}

const IncidentInfoSection: FC<IncidentInfoSectionProps> = ({
	formik,
	reportTypes,
	loadingReportTypes,
	setSelectedReportType,
	setSelectedAffectedType,
	servicesCustomer,
	getServicesCustomer,
}) => {
	const translate = useTranslator()
	const language = useLang()
	const authData = useContext(AuthContext)
	const { values, errors, setFieldValue } = formik

	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])

	const affectationTypeRepository = useMemo(() => HttpAffectationTypeRepository(authData), [authData])
	const networkElementTypeRepository = useMemo(() => HttpNetworkElementTypeRepository(authData), [authData])
	const reportClassificationRepository = useMemo(() => HttpReportClassificationRepository(authData), [authData])
	const degradationTypeRepository = useMemo(() => HttpDegradationTypeRepository(authData), [authData])

	const [affectationTypes, setAffectationTypes] = useState<IUfinetSelectOption[]>([])
	const [reportClassifications, setReportClassifications] = useState<IUfinetSelectOption[]>([])
	const [networkElementTypes, setNetworkElementTypes] = useState<IUfinetSelectOption[]>([])
	const [degradationTypes, setDegradationTypes] = useState<IUfinetSelectOption[]>()
	const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>()

	const { isLoading: loadingAffectationTypes } = useAffectionTypeFindAllQuery(affectationTypeRepository, {
		onSuccess: (params: AffectationType[]): void => {
			const mappedData = params.map((item) => AffectationType.mapAffectationTypesToSelectOption(item, language))
			setAffectationTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE')),
	})

	const { isLoading: loadingNetworkElementTypes } = useNetworkElementTypeFindAllQuery(networkElementTypeRepository, {
		onSuccess: (params: NetworkElementType[]): void => {
			const mappedData = params.map((item) => NetworkElementType.mapNetworkElementTypeToSelectOption(item, language))
			setNetworkElementTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.NETWORK_ELEMENT_TYPE')),
	})

	const { isLoading: loadingReportClassifications } = useReportClassificationFindAllQuery(
		reportClassificationRepository,
		{
			onSuccess: (params: ReportClassification[]): void => {
				const mappedData = params.map((item) =>
					ReportClassification.mapReportClassificationToSelectOption(item, language)
				)
				setReportClassifications(mappedData)
			},
			onError: () => toast.error(translate('FETCH.ERROR.REPORT_CLASSIFICATION')),
		}
	)

	const { isLoading: loadingDegradationTypes } = useDegradationTypeFindAllQuery(degradationTypeRepository, {
		onSuccess: (params: DegradationType[]): void => {
			const mappedData = params.map((item) => DegradationType.mapDegradationTypeToSelectOption(item, language))
			setDegradationTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.DEGRADATION_TYPE')),
	})

	const handleAffectedTypeChange = (val: any) => {
		setSelectedAffectedType(val)
		setFieldValue('affectedTypeId', val)
		setFieldValue('networkElementTypeId', { label: '', value: '' })
		setFieldValue('networkElementAffected', '')
		setFieldValue('serviceId', '')
	}

	const handleInputChange = useCallback(
		(global) => {
			if (global) {
				if (inputTimeout) {
					clearTimeout(inputTimeout)
				}

				const timeout = setTimeout(() => {
					getServicesCustomer(formik.values.clientSelect.value, global)
				}, 500)

				setInputTimeout(timeout)
			}
		},
		[formik.values.clientSelect.value, getServicesCustomer, inputTimeout]
	)

	return (
		<UfinetSectionBox title="incident_info" className="mb-5">
			<h4>{translate('TICKET.NEW.TITLE.INCIDENT.INFO')}</h4>
			<div className="row mt-3 mb-3">
				<UfinetSelect
					options={servicesCustomer}
					value={servicesCustomer?.find((option) => option.value === values.affectedService) || null}
					id="affected_service"
					labelTitle={translate('TICKET.NEW.PROJECT_CODE')}
					requiredIcon
					className="col-12 col-md-6"
					onChange={onChange(formik, 'affectedService')}
					onInputChange={handleInputChange}
					error={errors.affectedService?.label}
					isDisabled={!values.clientSelect?.value}
				/>
				<UfinetSelect
					options={affectationTypes}
					isLoadingOptions={loadingAffectationTypes}
					value={affectationTypes.find((option) => option.value === values.affectedTypeId.value) || null}
					id="affected_type_id"
					labelTitle={translate('TICKET.NEW.AFFECTED_TYPE')}
					requiredIcon
					className="col-12 col-md-6"
					onChange={handleAffectedTypeChange}
					error={errors.affectedTypeId?.label}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={values.serviceId}
					id="service_id"
					solid={false}
					labelTitle={translate('TICKET.NEW.SERVICE_ID')}
					requiredIcon={values.affectedTypeId?.value === AFFECTED_TYPES_IDS.CLIENT_SERVICE}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'serviceId')}
					error={errors.serviceId}
					isDisabled={values.affectedTypeId?.value !== AFFECTED_TYPES_IDS.CLIENT_SERVICE}
				/>
				<UfinetInput
					type="text"
					value={values.internalCustomerTicketNumber}
					error={errors.internalCustomerTicketNumber}
					id="internal_ticket_number"
					solid={false}
					labelTitle={translate('TICKET.NEW.INTERNAL_TICKET_NUMBER')}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'internalCustomerTicketNumber')}
				/>
			</div>
			<div className="row mt-3">
				<UfinetSelect
					options={networkElementTypes}
					isLoadingOptions={loadingNetworkElementTypes}
					value={networkElementTypes.find((option) => option.value === values.networkElementTypeId) || null}
					id="network_element_type_id"
					labelTitle={translate('TICKET.NEW.NETWORK_ELEMENT_TYPE')}
					className="col-12 col-md-6"
					onChange={onChange(formik, 'networkElementTypeId')}
					isDisabled={values.affectedTypeId?.value !== AFFECTED_TYPES_IDS.RED_ELEMENT}
					clearValues={values.affectedTypeId?.value !== AFFECTED_TYPES_IDS.RED_ELEMENT}
				/>
				<UfinetInput
					type="text"
					value={values.networkElementAffected}
					id="network_element_affected"
					solid={false}
					labelTitle={translate('TICKET.NEW.NETWORK_ELEMENT_AFFECTED')}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'networkElementAffected')}
					isDisabled={values.affectedTypeId?.value !== AFFECTED_TYPES_IDS.RED_ELEMENT}
				/>
			</div>
			<div className="row mt-3">
				<UfinetSelect
					options={reportTypes}
					isLoadingOptions={loadingReportTypes}
					value={reportTypes?.find((option) => option.value === values.reportType) || null}
					id="report_type"
					labelTitle={translate('TICKET.NEW.REPORT.TYPE')}
					requiredIcon
					className="col-12 col-md-6"
					onChange={(e) => {
						onChange(formik, 'reportType')(e)
						onChange(formik, 'degradationType')({ label: '', value: '' })
						setSelectedReportType(e as IUfinetSelectOption)
					}}
					error={errors.reportType?.label}
				/>
				<UfinetSelect
					options={degradationTypes}
					isLoadingOptions={loadingDegradationTypes}
					value={degradationTypes?.find((option) => option.value === values.degradationType) || null}
					id="degradation_type"
					labelTitle={translate('TICKET.NEW.DEGRADATION_TYPE')}
					requiredIcon={values.reportType?.value === REPORT_TYPES_IDS.DEGRADATION}
					className="col-12 col-md-6"
					isDisabled={values.reportType?.value !== REPORT_TYPES_IDS.DEGRADATION}
					onChange={onChange(formik, 'degradationType')}
					error={errors.degradationType?.label}
					clearValues={values.reportType?.value !== REPORT_TYPES_IDS.DEGRADATION}
				/>
			</div>
			<div className="row mt-3">
				<UfinetSelect
					options={reportClassifications}
					isLoadingOptions={loadingReportClassifications}
					value={reportClassifications?.find((option) => option.value === values.reportClassification) || null}
					id="report_classification"
					labelTitle={translate('TICKET.NEW.REPORT.CLASSIFICATION')}
					className="col-12 col-md-6"
					onChange={onChange(formik, 'reportClassification')}
				/>
				<DatePicker
					type={DatePickerTypeEnum.DATE_TIME}
					value={values.detectedDate}
					timeFormat="24"
					id="detection_date"
					label={translate('TICKET.NEW.DETECTION.DATE')}
					labelIconRequired
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'detectedDate')}
					error={errors.detectedDate}
					max={new Date()}
				/>
			</div>
			<div className="row mt-3">
				<UfinetInput
					type="text"
					value={values.incidentDescription}
					id="incident_description"
					labelTitle={translate('TICKET.NEW.INCIDENT_DESCRIPTION')}
					requiredIcon
					solid={false}
					className="col"
					onChange={onTextChange(formik, 'incidentDescription')}
					error={errors.incidentDescription}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { IncidentInfoSection }
