import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useFormik } from 'formik'
import { CorporateIncidentsSaveRequest } from 'src/modules/incidents/application/dto/CorporateIncidentsSaveRequest'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { ServicesCustomer } from 'src/modules/servicesCustomer/domain/ServicesCustomer'
import { HttpServicesCustomerRepository } from 'src/modules/servicesCustomer/infrastructure/HttpServicesCustomerRepository'
import { REPORT_TYPES_IDS } from 'src/utils/constants'
import { AuthContext, emptyUfinetSelectOption, IUfinetSelectOption, useLang, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import { emptyClientSelectOption, UfinetBox } from 'ufinet-web-components'
import { ActionButtons } from './components/ActionButtons'
import { AffectedServicesModal } from './components/AffectedServicesModal'
import { ContactInfoSectionCorporate } from './components/ContactInfoSectionCorporate'
import { IncidentInfoSectionCorporate } from './components/IncidentInfoSectionCorporate'
import { TicketInfoSectionCorporate } from './components/TicketInfoSectionCorporate'

const CreateCorporateIncidentPage: FC = () => {
	const translate = useTranslator()
	const navigate = useNavigate()
	const authData = useContext(AuthContext)
	const language = useLang()

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const servicesCustomerRepository = useMemo(() => HttpServicesCustomerRepository(authData), [authData])

	const nowDate = useMemo(() => new Date(), [])

	const emptyNumberUfinetSelectOption: IUfinetSelectOption<number> = {
		label: '',
		value: 0,
	}

	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
	const [affectedServices, setAffectedServices] = useState<IUfinetSelectOption[]>([])
	const [servicesCustomer, setServicesCustomer] = useState<IUfinetSelectOption[]>()
	const [selectedReportType, setSelectedReportType] = useState<IUfinetSelectOption>()
	const [showAffectedServicesModal, setShowAffectedServicesModal] = useState<boolean>(false)

	const getDegradationTypeSchemaValidation: () => Yup.ObjectSchema<
		{ label: string | null | undefined } | null,
		Yup.AnyObject,
		{ label: undefined } | null,
		''
	> = () =>
		selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()
			? Yup.object()
					.nullable()
					.shape({
						label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
					})
			: Yup.object().nullable().shape({
					label: Yup.string().nullable(),
				})

	const validationSchema = Yup.object().shape({
		reportContactId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		clientSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		notificationLang: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedService: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		internalCustomerTicketNumber: Yup.string().max(200, translate('ERROR.MAX_LENGTH')),
		reportType: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		countrySelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		corporateGroupSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		degradationType: getDegradationTypeSchemaValidation(),
		incidentDescription: Yup.string().required(translate('ERROR.REQUIRED')),
		detectionTimestamp: Yup.string().required(translate('ERROR.REQUIRED')),
		reportEmail: Yup.string().required(translate('ERROR.REQUIRED')),
		reportPhone: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.matches(/^\+?(\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4}[-. ]*){1,4}\d{1,4}$/, translate('ERROR.PHONE')),
	})

	const sendData = () => {
		setButtonDisabled(true)
		const request = CorporateIncidentsSaveRequest.fromValues(formik.values, affectedServices)
		if (affectedServices.length > 1) {
			incidentRepository
				.saveBulkCorporateIncident(request)
				.then(() => {
					toast.success(translate('TICKETS.SAVE.SUCCESS'))
					navigate('/list-tickets')
				})
				.catch(() => {
					toast.warning(translate('TICKETS.SAVE.ERROR'))
					setButtonDisabled(false)
				})
		} else {
			incidentRepository
				.saveCorporateIncident(request)
				.then(() => {
					toast.success(translate('TICKET.SAVE.SUCCESS'))
					navigate('/list-tickets')
				})
				.catch(() => {
					toast.warning(translate('TICKET.SAVE.ERROR'))
					setButtonDisabled(false)
				})
		}
	}

	const initialValues = useMemo(
		() => ({
			reportContactId: emptyUfinetSelectOption,
			reportEmail: authData.userData?.username || '',
			notificationGroup: emptyUfinetSelectOption,
			reportPhone: '',
			creationDate: nowDate,
			notificationLang: emptyNumberUfinetSelectOption,
			affectedService: emptyUfinetSelectOption,
			internalCustomerTicketNumber: '',
			reportType: emptyNumberUfinetSelectOption,
			degradationType: emptyNumberUfinetSelectOption,
			detectionTimestamp: undefined as unknown as Date,
			incidentDescription: '',
			visitDate: undefined as unknown as Date,
			visitContact: '',
			visitPhone: '',
			visitRequirements: '',
			countrySelect: emptyUfinetSelectOption,
			corporateGroupSelect: emptyUfinetSelectOption,
			clientSelect: emptyClientSelectOption,
			emailUserApplication: authData.userData?.username || '',
		}),
		[authData.userData]
	)

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	const getServicesCustomer = useCallback((clientId: string, global: string = '') => {
		servicesCustomerRepository
			.corporateFindAll(clientId, language, global)
			.then((res) => {
				const mappedData = ServicesCustomer.mapServicesCustomerToSelectOption(res)
				setServicesCustomer(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE'))
			})
	}, [])

	const addAffectedService = (affectedService: IUfinetSelectOption) => {
		if (!affectedServices.find((service) => service.value === affectedService.value)) {
			setAffectedServices([...affectedServices, affectedService])
			toast.success(translate('TICKET.NEW.AFFECTED_SERVICE.ADDED'), { autoClose: 800 })
			return
		}
		toast.error(translate('TICKET.NEW.AFFECTED_SERVICE.EXISTING'), { autoClose: 800 })
	}

	const deleteAffectedService = (affectedService: IUfinetSelectOption) => {
		const newAffectedServices = affectedServices.filter((service) => service.value !== affectedService.value)
		if (newAffectedServices.length === 0) {
			setShowAffectedServicesModal(false)
		}
		setAffectedServices(newAffectedServices)
	}

	return (
		<>
			<UfinetBox>
				<div>
					<TicketInfoSectionCorporate
						formik={formik}
						getServicesCustomer={getServicesCustomer}
						setAffectedServices={setAffectedServices}
						setServicesCustomer={setServicesCustomer}
					/>

					<IncidentInfoSectionCorporate
						formik={formik}
						affectedServices={affectedServices}
						setShowAffectedServicesModal={setShowAffectedServicesModal}
						selectedReportType={selectedReportType}
						servicesCustomer={servicesCustomer}
						addAffectedService={addAffectedService}
						setSelectedReportType={setSelectedReportType}
						getServicesCustomer={getServicesCustomer}
					/>

					<ContactInfoSectionCorporate formik={formik} />

					<ActionButtons onSubmit={formik.handleSubmit} isDisableButton={buttonDisabled} />
				</div>
			</UfinetBox>

			<AffectedServicesModal
				affectedServices={affectedServices}
				show={showAffectedServicesModal}
				handleClose={() => setShowAffectedServicesModal(false)}
				deleteAffectedService={deleteAffectedService}
			/>
		</>
	)
}

export { CreateCorporateIncidentPage }
