import { FC, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useFormik } from 'formik'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { AFFECTED_TYPES_IDS, REPORT_TYPES_IDS } from 'src/utils/constants'
import {
	AuthContext,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	useInternalUser,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

import { ContactInfoSection } from '@Pages/tickets/new/components/ContactInfoSection'
import { IncidentInfoSection } from '@Pages/tickets/new/components/IncidentInfoSection'
import { emptyClientSelectOption, UfinetBox } from 'ufinet-web-components'
import { FtthIncidentSaveRequest } from '../../../modules/incidents/application/dto/FtthIncidentSaveRequest'
import { NotificationGroup } from '../../../modules/notificationGroup/domain/NotificationGroup'
import { HttpNotificationGroupRepository } from '../../../modules/notificationGroup/infrastructure/HttpNotificationGroupRepository'
import { useReportTypeFindAllQuery } from '../../../modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from '../../../modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from '../../../modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { ServicesCustomer } from '../../../modules/servicesCustomer/domain/ServicesCustomer'
import { HttpServicesCustomerRepository } from '../../../modules/servicesCustomer/infrastructure/HttpServicesCustomerRepository'
import { ActionButtons } from './components/ActionButtons'
import { TicketInfoSection } from './components/TicketInfoSection'

const CreateFtthIncidentPage: FC = () => {
	const navigate = useNavigate()
	const translate = useTranslator()
	const authData = useContext(AuthContext)
	const nowDate = useMemo(() => new Date(), [])
	const language = useLang()
	const isInternal = useInternalUser()
	const [isDisableButton, setDisableButton] = useState(false)

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const notificationGroupRepository = useMemo(() => HttpNotificationGroupRepository(authData), [authData])
	const servicesCustomerRepository = useMemo(() => HttpServicesCustomerRepository(authData), [authData])
	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])

	const [notificationGroups, setNotificationGroups] = useState<IUfinetSelectOption[]>([])
	const [servicesCustomer, setServicesCustomer] = useState<IUfinetSelectOption[]>()
	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>([])
	const [selectedReportType, setSelectedReportType] = useState<IUfinetSelectOption>()
	const [selectedAffectedType, setSelectedAffectedType] = useState<IUfinetSelectOption>()

	const { isLoading: loadingReportTypes } = useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const mappedData = params.map((item) => ReportType.mapReportTypeToSelectOption(item, language))
			setReportTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})

	const getNotificationGroups = (corporativeGroupId: string, customerId: string) => {
		notificationGroupRepository
			.findAll(corporativeGroupId, customerId)
			.then((params) => {
				const mappedData = params.map((item: { notificationGroupId: string; notificationGroupName: string }) =>
					NotificationGroup.mapNotificationGroupToSelectOption(item)
				)
				setNotificationGroups(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.NOTIFICATION_GROUP'))
			})
	}

	const getServicesCustomer = (clientId: string, global: string = '') => {
		servicesCustomerRepository
			.ftthFindAll(clientId, language, global)
			.then((res) => {
				const mappedData = ServicesCustomer.mapServicesCustomerToSelectOption(res)
				setServicesCustomer(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE'))
			})
	}

	const getDegradationTypeSchemaValidation: () => Yup.ObjectSchema<
		{ label: string | null | undefined } | null,
		Yup.AnyObject,
		{ label: undefined } | null,
		''
	> = () =>
		selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()
			? Yup.object()
					.nullable()
					.shape({
						label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
					})
			: Yup.object().nullable().shape({
					label: Yup.string().nullable(),
				})

	const getServiceIdSchemaValidation: () => Yup.Schema<string | null | undefined> = () =>
		selectedAffectedType?.value == AFFECTED_TYPES_IDS.CLIENT_SERVICE.toString()
			? Yup.string().required(translate('ERROR.REQUIRED'))
			: Yup.string().nullable()

	const validationSchema = Yup.object().shape({
		affectedTypeId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		clientSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		notificationLanguageId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		serviceId: getServiceIdSchemaValidation(),
		internalCustomerTicketNumber: Yup.string().max(200, translate('ERROR.MAX_LENGTH')),
		reportType: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedService: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		degradationType: getDegradationTypeSchemaValidation(),
		detectedDate: Yup.string().required(translate('ERROR.REQUIRED')),
		incidentDescription: Yup.string().required(translate('ERROR.REQUIRED')),
		reportContactEmail: Yup.string().required(translate('ERROR.REQUIRED')),
		reportContactPhone: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.matches(/^\+?(\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4}[-. ]*){1,4}\d{1,4}$/, translate('ERROR.PHONE')),
	})

	const initialValues = useMemo(
		() => ({
			affectedTypeId: emptyUfinetSelectOption,
			clientSelect: emptyClientSelectOption,
			creationDate: nowDate,
			degradationType: emptyUfinetSelectOption,
			detectedDate: undefined as unknown as Date,
			fichero: undefined,
			incidentDescription: '',
			internalCustomerTicketNumber: '',
			networkElementAffected: '',
			networkElementTypeId: emptyUfinetSelectOption,
			notificationGroupId: emptyUfinetSelectOption,
			notificationLanguageId: emptyUfinetSelectOption,
			affectedService: emptyUfinetSelectOption,
			reportClassification: emptyUfinetSelectOption,
			reportContactEmail: authData.userData?.username || '',
			reportContactPhone: '',
			reportType: emptyUfinetSelectOption,
			serviceId: '',
			visitContactName: '',
			visitContactPhone: '',
			visitRequirements: '',
			visitDate: undefined as unknown as Date,
			emailUserApplication: authData.userData?.username || '',
		}),
		[authData.userData]
	)

	const sendData = () => {
		setDisableButton(true)
		incidentRepository
			.saveFtthIncident(FtthIncidentSaveRequest.fromValues(formik.values))
			.then(() => {
				toast.success(translate('TICKET.SAVE.SUCCESS'))
				navigate('/list-tickets')
			})
			.catch(() => {
				toast.warning(translate('TICKET.SAVE.ERROR'))
				setDisableButton(false)
			})
	}

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	return (
		<UfinetBox>
			<form>
				<TicketInfoSection
					formik={formik}
					isInternal={isInternal}
					notificationGroups={notificationGroups}
					setNotificationGroups={setNotificationGroups}
					getNotificationGroups={getNotificationGroups}
					setServicesCustomer={setServicesCustomer}
					getServicesCustomer={getServicesCustomer}
				/>
				<IncidentInfoSection
					formik={formik}
					reportTypes={reportTypes}
					loadingReportTypes={loadingReportTypes}
					setSelectedReportType={setSelectedReportType}
					setSelectedAffectedType={setSelectedAffectedType}
					servicesCustomer={servicesCustomer}
					getServicesCustomer={getServicesCustomer}
				/>
				<ContactInfoSection formik={formik} />
				<ActionButtons onSubmit={formik.handleSubmit} isDisableButton={isDisableButton} />
			</form>
		</UfinetBox>
	)
}

export { CreateFtthIncidentPage }
