import dayjs from 'dayjs'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { CorporateIncidentsSaveRequest } from '../application/dto/CorporateIncidentsSaveRequest'
import { CorporativeIncidentFindResponse } from '../application/dto/CorporativeIncidentFindResponse'
import { CreateIncidentActivityDTO } from '../application/dto/CreateIncidentActivityDTO'
import { FTTHIncidentFindResponse } from '../application/dto/FTTHIncidentFindResponse'
import { FtthIncidentSaveRequest } from '../application/dto/FtthIncidentSaveRequest'
import { IncidentActivityFindRequest } from '../application/dto/IncidentActivityFindRequest'
import { IncidentActivityFindResponse } from '../application/dto/IncidentActivityFindResponse'
import { IncidentExportRequest } from '../application/dto/IncidentExportRequest'
import { IncidentFindRequest } from '../application/dto/IncidentFindRequest'
import { IncidentFindResponse } from '../application/dto/IncidentFindResponse'
import { IncidentRepository } from '../domain/repository/IncidentRepository'

const url = `${process.env.REACT_APP_URL_BACK_GTI}incident`
const incidentActivityURL = `${process.env.REACT_APP_URL_BACK_GTI}incidentActivity`

export const HttpIncidentRepository: (authData: IAuthData) => IncidentRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
		notifyError: false,
	}

	return {
		saveCorporateIncident: async (incidents: CorporateIncidentsSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(`${url}/createCorporateIncidents`, incidents.tickets, {
				...fetchOptions,
				...options,
			}),
		saveBulkCorporateIncident: async (
			incidents: CorporateIncidentsSaveRequest,
			options?: FetchOptions
		): Promise<void> =>
			await FetchAPI.post(`${url}/createCorporateBatchIncidents`, incidents.tickets, {
				...fetchOptions,
				...options,
			}),
		saveFtthIncident: async (incident: FtthIncidentSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(`${url}/createFTTHIncidents`, [incident.ticket], {
				...fetchOptions,
				...options,
			}),
		findAll: async (request: IncidentFindRequest, options?: FetchOptions): Promise<IncidentFindResponse> => {
			const params = new URLSearchParams()

			for (const key in request) {
				const value = request[key as keyof IncidentFindRequest]
				if (value !== undefined && value !== null && value !== '') {
					if (Array.isArray(value)) {
						value.forEach((v: string) => {
							if (v !== undefined && v !== null && v !== '') {
								params.append(key, v)
							}
						})
					} else {
						params.append(key, value as string)
					}
				}
			}

			return await FetchAPI.get(`${url}/findAll?${params.toString()}`, {
				...fetchOptions,
				...options,
			})
		},
		exportIncidents: async (request: IncidentExportRequest): Promise<Response> => {
			const params = new URLSearchParams()

			for (const key in request) {
				const value = request[key as keyof IncidentExportRequest]
				if (value !== undefined && value !== null && value !== '') {
					if (Array.isArray(value)) {
						value.forEach((v: string) => {
							if (v !== undefined && v !== null && v !== '') {
								params.append(key, v)
							}
						})
					} else {
						params.append(key, value as string)
					}
				}
			}

			if (!params.get('dateTo')) {
				const dateFrom = params.get('dateFrom')
				params.set('dateTo', dayjs(dateFrom).add(90, 'days').format('YYYY-MM-DDTHH:mm:ss'))
			}

			return await fetch(`${url}/exportIncidents?${params.toString()}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					Authorization: `Bearer ${fetchOptions.authenticationData?.token?.accessToken}`,
					Apikey: fetchOptions.apiKey,
				},
			})
		},
		findCorporateIncident: async (
			incidentId: string,
			options?: FetchOptions
		): Promise<CorporativeIncidentFindResponse> =>
			await FetchAPI.get_params(
				`${url}/findCorporateIncident`,
				{ incidentId },
				{
					...fetchOptions,
					...options,
				}
			),
		findFTTHIncident: async (incidentId: string, options?: FetchOptions): Promise<FTTHIncidentFindResponse> =>
			await FetchAPI.get_params(
				`${url}/findFTTHIncident`,
				{ incidentId },
				{
					...fetchOptions,
					...options,
				}
			),
		findIncidentActivities: async (
			request: IncidentActivityFindRequest,
			options?: FetchOptions
		): Promise<IncidentActivityFindResponse> => {
			const params = new URLSearchParams()

			for (const key in request) {
				const value = request[key as keyof IncidentActivityFindRequest]
				if (value !== undefined && value !== null && value !== '') {
					if (Array.isArray(value)) {
						value.forEach((v: string) => {
							if (v !== undefined && v !== null && v !== '') {
								params.append(key, v)
							}
						})
					} else {
						params.append(key, value as string)
					}
				}
			}

			return await FetchAPI.get(`${incidentActivityURL}/findByIncidentId?${params.toString()}`, {
				...fetchOptions,
				...options,
			})
		},
		createIncidentActivity: async (activity: CreateIncidentActivityDTO, options?: FetchOptions): Promise<void> => {
			await FetchAPI.post(`${incidentActivityURL}/CreateIncidentActivity`, activity, {
				...fetchOptions,
				...options,
			})
		},
	}
}
