import { convertToLocalTimeString } from 'src/utils/dateUtils'
import { IUfinetSelectOption } from 'ufinet-web-functions'

import { CorporateIncident } from '../../domain/CorporateIncident'

export class CorporateIncidentsSaveRequest {
	tickets: CorporateIncident[]

	constructor(tickets: CorporateIncident[]) {
		this.tickets = tickets
	}

	static fromValues(
		obj: {
			reportContactId: IUfinetSelectOption
			reportEmail: string
			notificationGroup: IUfinetSelectOption
			reportPhone: string
			creationDate: Date
			notificationLang: IUfinetSelectOption<number>
			affectedService: IUfinetSelectOption
			internalCustomerTicketNumber: string
			reportType: IUfinetSelectOption<number>
			degradationType: IUfinetSelectOption<number>
			detectionTimestamp: Date
			incidentDescription: string
			visitDate: Date
			visitContact: string
			visitPhone: string
			visitRequirements: string
			countrySelect: IUfinetSelectOption
			corporateGroupSelect: IUfinetSelectOption
			clientSelect: IUfinetSelectOption
			emailUserApplication: string
		},
		affectedServices: IUfinetSelectOption[]
	) {
		const tickets: CorporateIncident[] =
			affectedServices.length > 0
				? affectedServices.map((service) => {
						const ticket: CorporateIncident = {
							notificationGroupId: obj.notificationGroup.value || undefined,
							reportContactId: obj.reportContactId.value,
							reportContactEmail: obj.reportEmail,
							reportContactPhone: obj.reportPhone,
							observation: '',
							notificationLanguageId: obj.notificationLang.value,
							customerId: obj.clientSelect.value,
							serviceAffectedId: service.value,
							administrativeCode: service.name || undefined,
							reportTypeId: obj.reportType.value,
							degradationTypeId: obj.degradationType.value || undefined,
							detectedDate: convertToLocalTimeString(obj.detectionTimestamp),
							description: obj.incidentDescription,
							internalCustomerTicketNumber: obj.internalCustomerTicketNumber || undefined,
							visitingHours: convertToLocalTimeString(obj.visitDate),
							visitContactName: obj.visitContact,
							visitContactPhone: obj.visitPhone,
							visitRequirements: obj.visitRequirements,
							typeId: 912790000,
							reportOriginId: 3,
							emailUserApplication: obj.emailUserApplication,
						}

						return ticket
					})
				: [
						{
							notificationGroupId: obj.notificationGroup.value || undefined,
							reportContactId: obj.reportContactId.value,
							reportContactEmail: obj.reportEmail,
							reportContactPhone: obj.reportPhone,
							observation: '',
							notificationLanguageId: obj.notificationLang.value,
							customerId: obj.clientSelect.value,
							serviceAffectedId: obj.affectedService.value,
							administrativeCode: obj.affectedService.name || undefined,
							reportTypeId: obj.reportType.value,
							degradationTypeId: obj.degradationType.value || undefined,
							detectedDate: convertToLocalTimeString(obj.detectionTimestamp),
							description: obj.incidentDescription,
							internalCustomerTicketNumber: obj.internalCustomerTicketNumber || undefined,
							visitingHours: convertToLocalTimeString(obj.visitDate),
							visitContactName: obj.visitContact,
							visitContactPhone: obj.visitPhone,
							visitRequirements: obj.visitRequirements,
							typeId: 912790000,
							reportOriginId: 3,
							emailUserApplication: obj.emailUserApplication,
						},
					]

		return new CorporateIncidentsSaveRequest(tickets)
	}
}
