import React, { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useDegradationTypeFindAllQuery } from 'src/modules/degradationTypes/application/DegradationTypeQueries'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { HttpDegradationTypeRepository } from 'src/modules/degradationTypes/infrastructure/HttpDegradationTypeRepository'
import { useReportTypeFindAllQuery } from 'src/modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from 'src/modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { REPORT_TYPES_IDS } from 'src/utils/constants'
import {
	AuthContext,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import { faAdd, faFileEdit } from '@fortawesome/free-solid-svg-icons'

import {
	DatePicker,
	DatePickerTypeEnum,
	TooltipButton,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'

interface IncidentInfoSectionCorporateProps {
	formik: any
	servicesCustomer?: IUfinetSelectOption[]
	affectedServices: IUfinetSelectOption[]
	selectedReportType?: IUfinetSelectOption
	addAffectedService: (affectedService: IUfinetSelectOption) => void
	setShowAffectedServicesModal: (value: React.SetStateAction<boolean>) => void
	setSelectedReportType: Dispatch<SetStateAction<IUfinetSelectOption<string> | undefined>>
	getServicesCustomer: (clientId: string, global?: string) => void
}

const IncidentInfoSectionCorporate: FC<IncidentInfoSectionCorporateProps> = ({
	formik,
	servicesCustomer,
	affectedServices,
	selectedReportType,
	addAffectedService,
	setShowAffectedServicesModal,
	setSelectedReportType,
	getServicesCustomer,
}) => {
	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>()
	const [degradationTypes, setDegradationTypes] = useState<IUfinetSelectOption[]>()
	const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>()

	const authData = useContext(AuthContext)
	const translate = useTranslator()
	const language = useLang()
	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])
	const nowDate = useMemo(() => new Date(), [])

	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])
	const degradationTypeRepository = useMemo(() => HttpDegradationTypeRepository(authData), [authData])

	const { isLoading: loadingReportTypes } = useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const mappedData = params.map((item) => ReportType.mapReportTypeToSelectOption(item, language))
			setReportTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})
	const { isLoading: loadingDegradationTypes } = useDegradationTypeFindAllQuery(degradationTypeRepository, {
		onSuccess: (params: DegradationType[]): void => {
			const mappedData = params.map((item) => DegradationType.mapDegradationTypeToSelectOption(item, language))
			setDegradationTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.DEGRADATION_TYPE')),
	})

	const handleInputChange = useCallback(
		(global) => {
			if (global) {
				if (inputTimeout) {
					clearTimeout(inputTimeout)
				}

				const timeout = setTimeout(() => {
					getServicesCustomer(formik.values.clientSelect.value, global)
				}, 500)

				setInputTimeout(timeout)
			}
		},
		[formik.values.clientSelect.value, getServicesCustomer, inputTimeout]
	)

	return (
		<UfinetSectionBox title="incident_info" className="mb-5 d-flex flex-column">
			<h4>{translate('TICKET.NEW.TITLE.INCIDENT.INFO')}</h4>
			<div className="row">
				<div className="col-12 col-md-6 mb-5">
					<div className="d-flex gap-3">
						<UfinetSelect
							options={servicesCustomer}
							value={formik.values.affectedService}
							id="affected_service"
							labelTitle={translate('TICKET.NEW.AFFECTED_SERVICE')}
							requiredIcon
							className="col"
							onChange={onChange(formik, 'affectedService')}
							onInputChange={handleInputChange}
							error={formik.errors.affectedService?.label}
							isDisabled={!formik.values.clientSelect.value}
						/>
						<div>
							<TooltipButton
								buttonProps={{
									id: 'add_affected_service',
									type: 'button',
									secondaryButton: true,
									icon: faAdd,
									onClick: () => addAffectedService(formik.values.affectedService),
									isDisabled: !formik.values.affectedService.value || !formik.values.clientSelect.value,
									style: { maxWidth: 38, marginTop: 28 },
									className: 'd-flex align-items-center justify-content-center',
								}}
								tooltipProps={{
									className: 'tooltip-dark',
									placement: 'top-end',
									showArrow: false,
								}}
								active
								behaviour="hover"
								hideMethod="unrender"
								children={translate('TICKET.NEW.ADD_AFFECTED_SERVICE')}
							/>
						</div>
						<div
							style={{ marginTop: 28, maxWidth: 38, maxHeight: 38 }}
							className="col-1 me-3 d-flex align-items-center justify-content-center position-relative"
						>
							<TooltipButton
								buttonProps={{
									id: 'view_affected_service',
									type: 'button',
									secondaryButton: true,
									icon: faFileEdit,
									onClick: () => setShowAffectedServicesModal(true),
									isDisabled: affectedServices.length === 0,
									style: { maxWidth: 38 },
									className: 'd-flex align-items-center justify-content-center',
								}}
								tooltipProps={{
									className: 'tooltip-dark',
									containerPadding: 0,
									placement: 'top-end',
									showArrow: false,
								}}
								active
								behaviour="hover"
								hideMethod="unrender"
								children={translate('TICKET.NEW.VIEW_AFFECTED_SERVICES')}
							/>
							{affectedServices.length > 0 && (
								<div
									className="position-absolute d-flex align-items-center justify-content-center rounded-circle"
									style={{ width: 20, height: 20, backgroundColor: '#0065A4', color: '#fff', top: -5, right: -5 }}
								>
									{affectedServices.length}
								</div>
							)}
						</div>
					</div>
				</div>
				<UfinetInput
					type="text"
					value={formik.values.internalCustomerTicketNumber}
					error={formik.errors.internalCustomerTicketNumber}
					id="internal_ticket_number"
					solid={false}
					labelTitle={translate('TICKET.NEW.INTERNAL_TICKET_NUMBER')}
					className="col-12 col-md-6 mb-5"
					onChange={onTextChange(formik, 'internalCustomerTicketNumber')}
				/>
			</div>
			<div className="row">
				<UfinetSelect
					options={reportTypes}
					value={formik.values.reportType}
					id="report_type"
					labelTitle={translate('TICKET.NEW.REPORT.TYPE')}
					requiredIcon
					className="col-12 col-md-4 mb-5"
					isLoadingOptions={loadingReportTypes}
					onChange={(e) => {
						onChange(formik, 'reportType')(e)
						onChange(formik, 'degradationType')(emptyUfinetSelectOption)
						setSelectedReportType(e as IUfinetSelectOption)
					}}
					error={formik.errors.reportType?.label}
				/>
				<UfinetSelect
					options={degradationTypes}
					value={formik.values.degradationType}
					id="degradation_type"
					labelTitle={translate('TICKET.NEW.DEGRADATION_TYPE')}
					requiredIcon={selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()}
					className="col-12 col-md-4 mb-5"
					isLoadingOptions={loadingDegradationTypes}
					isDisabled={formik.values.reportType.value !== REPORT_TYPES_IDS.DEGRADATION}
					onChange={onChange(formik, 'degradationType')}
					error={formik.errors.degradationType?.label}
				/>
				<DatePicker
					type={DatePickerTypeEnum.DATE_TIME}
					value={formik.values.detectionTimestamp}
					timeFormat="24"
					id="detection_timestamp"
					label={translate('TICKET.NEW.DETECTION_TIMESTAMP')}
					labelIconRequired
					className="col-12 col-md-4 mb-5"
					onChange={onTextChange(formik, 'detectionTimestamp')}
					error={`${formik.errors.detectionTimestamp || ''}`}
					max={nowDate}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.incidentDescription}
					id="incident_description"
					labelTitle={translate('TICKET.NEW.INCIDENT_DESCRIPTION')}
					requiredIcon
					solid={false}
					className="col"
					onChange={onTextChange(formik, 'incidentDescription')}
					error={formik.errors.incidentDescription}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { IncidentInfoSectionCorporate }
