import { useQuery } from 'react-query'

import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { IncidentExportRequest } from './dto/IncidentExportRequest'

export const useIncidentExportQuery = (
	incidentRepository: IncidentRepository,
	incidentExportRequest: IncidentExportRequest,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (response: Response) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('export-incidents-query', async () => incidentRepository.exportIncidents(incidentExportRequest), {
		staleTime: 0,
		onSuccess,
		onError,
		refetchOnMount: false,
		enabled: false,
	})
}
