import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import { HttpNotificationGroupRepository } from 'src/modules/notificationGroup/infrastructure/HttpNotificationGroupRepository'
import { useNotificationLanguageFindAllQuery } from 'src/modules/notificationLanguages/application/NotificationLanguageQueries'
import { NotificationLanguage } from 'src/modules/notificationLanguages/domain/NotificationLanguage'
import { HttpNotificationLanguageRepository } from 'src/modules/notificationLanguages/infrastructure/HttpNotificationLanguageRepository'
import {
	AuthContext,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useInternalUser,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import {
	ContactSelect,
	ContactSelectHandle,
	DatePicker,
	DatePickerTypeEnum,
	Filter,
	IDisabledOptions,
	IFilterHiddenOptions,
	IFilterState,
	IRequiredOptions,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'

interface TicketInfoSectionCorporateProps {
	formik: any
	getServicesCustomer: (clientId: string, global?: string) => void
	setAffectedServices: (value: React.SetStateAction<IUfinetSelectOption[]>) => void
	setServicesCustomer: (value: React.SetStateAction<IUfinetSelectOption[] | undefined>) => void
}

const TicketInfoSectionCorporate: FC<TicketInfoSectionCorporateProps> = ({
	formik,
	getServicesCustomer,
	setAffectedServices,
	setServicesCustomer,
}) => {
	const contactRef = useRef<ContactSelectHandle>(null)
	const [notificationGroups, setNotificationGroups] = useState<IUfinetSelectOption[]>([])
	const [notificationLangs, setNotificationLangs] = useState<IUfinetSelectOption[]>()

	const authData = useContext(AuthContext)
	const isInternal = useInternalUser()
	const translate = useTranslator()
	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])
	const language = useLang()

	const notificationGroupRepository = useMemo(() => HttpNotificationGroupRepository(authData), [authData])
	const notificationLangRepository = useMemo(() => HttpNotificationLanguageRepository(authData), [authData])

	useEffect(() => {
		if (formik.values.clientSelect.value) {
			const request = {
				clientIds: [formik.values.clientSelect.value],
			}
			contactRef.current?.fillSelect(request)
		}
	}, [formik.values.clientSelect])

	const filterState: IFilterState = {
		countrySelect: null,
		corporateGroupSelect: null,
		clientSelect: null,
	}

	const requiredOptions: IRequiredOptions = {
		requiredCountry: true,
		requiredCorporateGroup: true,
		requiredClient: true,
		requiredContact: false,
		requiredReference: false,
		requiredFinalClient: false,
	}

	const hiddenOptions: IFilterHiddenOptions = {
		hideCountry: false,
		hideCorporateGroup: false,
		hideClient: false,
		hideContact: true,
		hideReference: true,
		hideFinalClient: true,
		hideSubmit: true,
	}

	const disabledOptions: IDisabledOptions = {
		allowCountrySelection: true,
		allowCorporateGroupSelection: Boolean(formik.values.countrySelect.value),
		allowClientSelection: Boolean(formik.values.corporateGroupSelect.value),
		allowContact: false,
		allowReference: false,
		allowFinalClient: false,
		allowSubmit: false,
	}

	const { isLoading: loadingNotificationLangs } = useNotificationLanguageFindAllQuery(notificationLangRepository, {
		onSuccess: (params: NotificationLanguage[]): void => {
			const mappedData = params.map((item) =>
				NotificationLanguage.mapNotificationLanguageToSelectOption(item, language)
			)
			setNotificationLangs(mappedData)
		},
	})

	const getNotificationGroups = (corporativeGroupId: string, customerId: string) => {
		notificationGroupRepository
			.findAll(corporativeGroupId, customerId)
			.then((params) => {
				const mappedData = params.map((item: { notificationGroupId: string; notificationGroupName: string }) =>
					NotificationGroup.mapNotificationGroupToSelectOption(item)
				)
				setNotificationGroups(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.NOTIFICATION_GROUP'))
			})
	}

	return (
		<UfinetSectionBox title="ticket_info" className="mb-5">
			<h4>{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
			<div>
				<Filter
					internalUser={isInternal}
					setFilter={() => filterState}
					required={requiredOptions}
					hidden={hiddenOptions}
					disabled={disabledOptions}
					afterCountryChange={onChange(formik, 'countrySelect')}
					afterCorporateGroupChange={(val) => {
						onChange(formik, 'corporateGroupSelect')(val)
						if (val) {
							setAffectedServices([])
						} else {
							setNotificationGroups([])
							setAffectedServices([])
							onChange(formik, 'reportContactId')({ value: '', label: '' })
							onChange(formik, 'notificationGroup')({ value: '', label: '' })
							onChange(formik, 'notificationLang')({ value: '', label: '' })
							onChange(formik, 'reportPhone')('')
						}
					}}
					afterClientChange={(val) => {
						onChange(formik, 'clientSelect')(val)
						if (val) {
							getNotificationGroups(formik.values.corporateGroupSelect.value, val.value)
							getServicesCustomer(val.value)
							setAffectedServices([])
						} else {
							setNotificationGroups([])
							setServicesCustomer([])
							setAffectedServices([])
							onChange(formik, 'reportContactId')({ value: '', label: '' })
							onChange(formik, 'notificationGroup')({ value: '', label: '' })
							onChange(formik, 'notificationLang')({ value: '', label: '' })
							onChange(formik, 'reportPhone')('')
						}
					}}
				/>
			</div>
			<div className="row">
				<ContactSelect
					className="col-12 col-md-4 mb-5"
					ref={contactRef}
					isDisabled={!formik.values.clientSelect.value}
					error={formik.errors.reportContactId?.label}
					value={formik.values.reportContactId}
					labelTitle={translate('TICKET.NEW.REPORT.USER')}
					requiredIcon
					onChange={onChange(formik, 'reportContactId')}
				/>
				<UfinetInput
					type="text"
					value={formik.values.reportEmail}
					id="report_email"
					isDisabled
					requiredIcon
					solid={false}
					error={formik.errors.reportEmail}
					labelTitle={translate('TICKET.NEW.REPORT.EMAIL')}
					className="col-12 col-md-4 mb-5"
					onChange={onTextChange(formik, 'reportEmail')}
				/>
				<UfinetSelect
					options={notificationGroups}
					value={formik.values.notificationGroup}
					id="notification_group"
					labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
					className="col-12 col-md-4 mb-5"
					onChange={onChange(formik, 'notificationGroup')}
					isDisabled={!formik.values.clientSelect.value}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.reportPhone}
					id="report_phone"
					requiredIcon
					solid={false}
					error={formik.errors.reportPhone}
					labelTitle={translate('TICKET.NEW.REPORT.PHONE')}
					className="col-12 col-md-4"
					onChange={onTextChange(formik, 'reportPhone')}
				/>
				<DatePicker
					value={formik.values.creationDate}
					timeFormat="24"
					type={DatePickerTypeEnum.DATE_TIME}
					id="creation_timestamp"
					label={translate('TICKET.NEW.CREATION_DATE')}
					className="col-12 col-md-4"
					disabled={true}
					onChange={() => {}}
				/>
				<UfinetSelect
					options={notificationLangs}
					isLoadingOptions={loadingNotificationLangs}
					value={formik.values.notificationLang}
					error={formik.errors.notificationLang?.label}
					id="notification_lang"
					requiredIcon
					labelTitle={translate('TICKET.NEW.NOTIFICATION_LANGUAGE')}
					className="col-12 col-md-4"
					onChange={onChange(formik, 'notificationLang')}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { TicketInfoSectionCorporate }
